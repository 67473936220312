/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "functions";
@import "variables";
@import "mixins";
@import "root";
@import "reboot";
@import "type";
@import "images";
@import "code";
@import "grid";
@import "tables";
@import "forms";
@import "buttons";
@import "transitions";
@import "dropdown";
@import "button-group";
@import "input-group";
@import "custom-forms";
@import "nav";
@import "navbar";
@import "card";
@import "breadcrumb";
@import "pagination";
@import "badge";
@import "jumbotron";
@import "alert";
@import "progress";
@import "media";
@import "list-group";
@import "close";
@import "toasts";
@import "modal";
@import "tooltip";
@import "popover";
@import "carousel";
@import "spinners";
@import "utilities";
@import "print";

//Custom Styles Start Here...

//Colours
$light-tan: rgb(236, 215, 191);
$mid-tan: rgb(187, 145, 127);
$dark-tan: rgb(47, 19, 16);
$dark-tan-tint: rgba(47, 19, 16, 0.4);
$lightest-tan: rgb(246, 236, 224);
$orange-highlight: #f8951d;

//General Styles
img {
  max-width: 100%;
}

body {
  font-family: brandon-grotesque, sans-serif;
  font-weight: 400;
  font-style: normal;
  line-height: 1.8;
  color: $dark-tan;
}

//Header
.header {
  color: $light-tan;
  background-image: url(../images/header-bg.jpg);
  background-repeat: repeat;
  background-position: center center;
  font-weight: 700;
  border-bottom: 10px solid $dark-tan;

  a {
    color: $light-tan;
  }
}

//Hero Image
.jumbotron {
  padding-top: 20%;
  padding-bottom: 20%;
  background-color: $dark-tan;
  //background-image: url(../images/cc_hero_image.jpg);
  //background-repeat: no-repeat;
  //background-position: center center;
  //background-attachment: fixed;
  //background-size: cover;
  background: transparent;

  &:after {
    content: "";
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    display: block;
    background: url(../images/cc_hero_image.jpg) no-repeat center center;
    background-size: cover;

  }

  h1 {
    color: $light-tan;
    font-size: 2.5rem;
    font-family: sarah-script, sans-serif;

    @include media-breakpoint-up(sm) {
      font-size: 3.5rem;
    }
  }

  a {
    position: relative;
    color: whitesmoke;
    display: block;
    padding-top: 1rem;
    padding-bottom: 2px;
    letter-spacing: 0.05rem;
    font-weight: 700;
    transition: all 0.3s;

    &:hover {
      color: $light-tan;
      text-decoration: none;
    }
  }
}

@media only screen and (min-device-width: 414px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 3) {
  .jumbotron {
    background-attachment: scroll;
  }
}

//Main Text

.main-content-outer {
  background-color: rgba(255, 255, 255, .95);
  border-top: 10px solid $dark-tan;
}

.main-content {
  font-size: 1.2rem;
  color: $dark-tan;
}

//Image Gallery
.image-gallery {
  border-top: 10px solid $dark-tan;
  border-bottom: 10px solid $dark-tan;
  cursor: pointer;
  background-color: white;

  .gallery-image-container {
    overflow: hidden;
  }

  .gallery-image {
    opacity: 0.8;
    transition: all 0.3s;
    min-width: 100%;

    &:hover {
      opacity: 1;
    }
  }
}

//Testimonials
.testimonials {
  background-color: $lightest-tan;
  color: $dark-tan;

  h4 {
    color: $orange-highlight;
  }

  h3 {
    font-family: pacifico, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 4rem;
    line-height: 1;
  }

  h6,
  h5 {
    color: $mid-tan;
  }

  h6 {
    line-height: 1.6;
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }
}

//Footer
footer {
  background-color: $dark-tan;
  color: $light-tan;
}